// Navigation.js
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-scroll";
import "./Navigation.css";
import MenuIcon from "@mui/icons-material/Menu";
import KaikasButton from "../Web3/Connect";

const Navigation = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuRef = useRef(null);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // 클릭 이벤트를 document에 추가하여 메뉴 이외를 클릭했을 때 메뉴를 닫습니다.
    window.addEventListener("click", handleOutsideClick, { capture: true });

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("click", handleOutsideClick, {
        capture: true,
      });
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleOutsideClick = (event) => {
    // menuRef가 존재하고, 클릭한 요소가 menuRef의 영역 내부에 없다면 메뉴를 닫습니다.
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  if (windowWidth > 768) {
    return (
      <nav className="nav">
        <ul>
          <li>
            <Link to="about" smooth={true} duration={500}>
              Intro
            </Link>
          </li>
          <li>
            <Link to="life-cycle" smooth={true} duration={500}>
              YOUTH Lifecycle
            </Link>
          </li>
          <li>
            <Link to="voucher" smooth={true} duration={500}>
              YOUTH Voucher
            </Link>
          </li>
          <li>
            <Link to="project" smooth={true} duration={500}>
              YOUTH Project
            </Link>
          </li>
          <li>
            <Link to="faq" smooth={true} duration={500}>
              FAQ
            </Link>
          </li>
          <li>
            <KaikasButton />
          </li>
        </ul>
      </nav>
    );
  } else {
    return (
      <div className="mobile-menu" ref={menuRef}>
        <button className="menu-button" onClick={toggleMenu}>
          {isMenuOpen ? null : <MenuIcon className="menu-icon" />}
        </button>
        {isMenuOpen && (
          <div className="menu" onClick={handleOutsideClick}>
            <Link to="about" smooth={true} duration={500} onClick={toggleMenu}>
              Intro
            </Link>
            <Link
              to="life-cycle"
              smooth={true}
              duration={500}
              onClick={toggleMenu}
            >
              YOUTH Lifecycle
            </Link>
            <Link
              to="voucher"
              smooth={true}
              duration={500}
              onClick={toggleMenu}
            >
              YOUTH Voucher
            </Link>
            <Link
              to="project"
              smooth={true}
              duration={500}
              onClick={toggleMenu}
            >
              YOUTH Project
            </Link>
            <Link to="faq" smooth={true} duration={500} onClick={toggleMenu}>
              FAQ
            </Link>
            <KaikasButton />
          </div>
        )}
      </div>
    );
  }
};

export default Navigation;
