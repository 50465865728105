// pages/Body.js
import React, { useEffect, useState, useRef } from "react";
import "./Body.css";
import Header from "../Header/Header";
import FAQSection from "./FAQ";

const Body = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const gridRef = useRef(null);
  const gridRef2 = useRef(null);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          }
        });
      },
      { threshold: 0.5 }
    );

    if (gridRef.current && windowWidth > 768) {
      observer.observe(gridRef.current);
    }

    if (gridRef2.current && windowWidth > 768) {
      observer.observe(gridRef2.current);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      observer.disconnect();
    };
  }, [windowWidth]); // Include windowWidth in the dependency array

  return (
    <div className="body">
      <Header />
      <section
        id="home"
        className="flex-mobile justify-center align-center main-section"
      >
        <div className="flex flex-column section-contents gap-3">
          <p className="main-title">
            The incentive platform for <span>YOUTH</span>
          </p>

          <p className="main-content">
            The Youth Project is a project to prove the sharing and volunteering
            of YOUTH through blockchain technology, and to create a virtuous
            cycle of sharing and volunteering by rewarding provers.
          </p>
        </div>

        <div className="section-contents"></div>
      </section>
      <section
        className="flex flex-column justify-center align-center"
        id="about"
      >
        <p className="title">
          What is <span>YOUTH</span> project?
        </p>
        <div className="grid grid-cols-2 margin-3" ref={gridRef}>
          <div>
            <div className="model-item flex flex-column gap-2 box-padding">
              <div className="flex flex-column margin-1 align-center gap-1">
                <img src="/images/permissionless.svg" alt="model" />
                <p className="model-title">Permissionless</p>
              </div>
              <p className="model-description flex justify-center text-center">
                Anyone can join the YOUTH project
              </p>
            </div>
          </div>

          <div>
            <div className="model-item flex flex-column gap-2 box-padding">
              <div className="flex flex-column margin-1 align-center gap-1">
                <img src="/images/trustless.svg" alt="model" />
                <p className="model-title">Trustless</p>
              </div>
              <p className="model-description flex justify-center text-center">
                Anyone can trust your proof of activity
              </p>
            </div>
          </div>

          <div>
            <div className="model-item flex flex-column gap-2 box-padding">
              <div className="flex flex-column margin-1 align-center gap-1">
                <img src="/images/incentivization.svg" alt="model" />
                <p className="model-title">Incentivization</p>
              </div>
              <p className="model-description flex justify-center text-center">
                Anyone can earn rewards for their activity
              </p>
            </div>
          </div>

          <div>
            <div className="model-item flex flex-column gap-2 box-padding">
              <div className="flex flex-column margin-1 align-center gap-1">
                <img src="/images/liquidity.svg" alt="model" />
                <p className="model-title">Liquidity</p>
              </div>
              <p className="model-description flex justify-center text-center">
                We bring transparency, efficiency and liquidity to the activity
                of youth
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id="life-cycle"
        className="flex flex-column justify-center align-center gap-5"
      >
        <p className="title">
          The <span>YOUTH</span> Project Life Cycle
        </p>
        {windowWidth > 1000 ? (
          <div className="flex-mobile align-center gap-10 margin-top-5 margin-bottom-5">
            <div className="flex flex-column">
              <div
                className="flex flex-column align-center"
                style={{ position: "relative", top: "-36vh" }}
              >
                <img
                  src="/images/dovolunteerwork.svg"
                  alt="dovolunteerwork"
                  className="cycle-img"
                />
                <p className="section-contents font-size-20 text-center">
                  1. Do volunteer work <br />
                  <br />
                  Complete your volunteer activity.
                </p>
              </div>
              <div
                className="flex flex-column align-center"
                style={{ position: "relative", top: "4vh" }}
              >
                <img
                  src="/images/issueavoucher.svg"
                  alt="issueavoucher"
                  className="cycle-img"
                />
                <p className="section-contents font-size-20 text-center">
                  3. Submit proof <br />
                  <br /> Get an authorized voucher from YOUTH Project.
                </p>
              </div>
            </div>

            <img src="/images/cycle.svg" alt="cycle" />

            <div className="flex flex-column">
              <div
                className="flex flex-column align-center"
                style={{ position: "relative", top: "0vh" }}
              >
                <img
                  src="/images/submitproof.svg"
                  alt="submitproof"
                  className="cycle-img"
                />
                <p className="section-contents font-size-20 text-center">
                  2. Issue a voucher <br />
                  <br />
                  Submit your proof to YOUTH Project.
                </p>
              </div>

              <div
                className="flex flex-column align-center"
                style={{ position: "relative", top: "37vh" }}
              >
                <img
                  src="/images/tradeyourvoucher.svg"
                  alt="tradeyourvoucher"
                  className="cycle-img"
                />
                <p className="section-contents font-size-20 text-center">
                  4. Trade your voucher <br />
                  <br />
                  Trade your YOUTH Project-authorized voucher.
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex-mobile align-center gap-5">
            <div className="flex flex-column align-center">
              <img
                src="/images/dovolunteerwork.svg"
                alt="dovolunteerwork"
                className="cycle-img"
              />
              <p className="section-contents font-size-20 text-center">
                1. Do volunteer work <br />
                <br />
                Complete your volunteer activity.
              </p>
            </div>
            <div className="flex flex-column align-center">
              <img
                src="/images/submitproof.svg"
                alt="submitproof"
                className="cycle-img"
              />
              <p className="section-contents font-size-20 text-center">
                2. Issue a voucher <br />
                <br />
                Submit your proof to YOUTH Project.
              </p>
            </div>
            <div className="flex flex-column align-center">
              <img
                src="/images/issueavoucher.svg"
                alt="issueavoucher"
                className="cycle-img"
              />
              <p className="section-contents font-size-20 text-center">
                3. Submit proof <br />
                <br /> Get an authorized voucher from YOUTH Project.
              </p>
            </div>
            <div className="flex flex-column align-center">
              <img
                src="/images/tradeyourvoucher.svg"
                alt="tradeyourvoucher"
                className="cycle-img"
              />
              <p className="section-contents font-size-20 text-center">
                4. Trade your voucher <br />
                <br />
                Trade your YOUTH Project-authorized voucher.
              </p>
            </div>
          </div>
        )}

        {/* <button className="btn box-shadow padding-1">발급하기</button> */}
      </section>
      <section
        id="voucher"
        className="flex flex-column justify-center align-center"
      >
        <p className="title">
          The <span>YOUTH</span> Voucher
        </p>
        <div className="flex-mobile align-center gap-3">
          <p className="section-contents font-size-20 text-center">
            Youth Voucher is a certificate issued on the Klaytn Network to
            people who practice sharing and volunteering.
          </p>
          <img src="/images/issueavoucher.svg" alt="voucher" />
        </div>
        <button className="btn box-shadow padding-1">Learn More</button>
      </section>
      <section
        id="project"
        className="flex flex-column justify-center align-center"
      >
        <p className="title">
          About <span>YOUTH</span> Project
        </p>
        <div className="grid grid-cols-2" ref={gridRef2}>
          <div className="flex flex-column align-center">
            <img src="/images/project-1.svg" alt="project1" />
            <p className="font-size-20 text-center bold">
              Blockchain based Blood Donation Management Method
            </p>
          </div>
          <div className="flex flex-column align-center">
            <img src="/images/project-2.svg" alt="project2" />
            <p className="font-size-20 text-center bold">
              CrowdDoing Blockchain : Volunteer
            </p>
          </div>
          <div className="flex flex-column align-center">
            <img src="/images/project-3.svg" alt="project3" />
            <p className="font-size-20 text-center bold">
              Find volunteering opportunities
            </p>
          </div>
          <div className="flex flex-column align-center">
            <img src="/images/project-4.svg" alt="project4" />
            <p className="font-size-20 text-center bold">
              Five Examples of Blockchain in Charitable Giving
            </p>
          </div>
        </div>
      </section>
      <FAQSection />
    </div>
  );
};

export default Body;
