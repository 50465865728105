import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { styled } from "@mui/system";

const StyledAccordion = styled(Accordion)({
  width: "100%",
  borderRadius: "4px",
  boxShadow: "1px 2px 4px 0px rgba(0, 0, 0, 0.25)",
});

const StyledAccordionSummary = styled(AccordionSummary)({
  "& .MuiAccordionSummary-content": {
    justifyContent: "space-between",
    padding: "1rem",
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: "0.25rem",
});

const StyledTypography = styled(Typography)({
  fontSize: "18px",
});

const FAQSection = () => {
  const faqData = [
    {
      question: "What is YOUTH Project’s vision?",
      answer: (
        <div>
          <StyledTypography style={{ padding: "1.5rem" }}></StyledTypography>
        </div>
      ),
    },
    {
      question: "What is YOUTH Voucher?",
      answer: (
        <div>
          <StyledTypography style={{ padding: "1.5rem" }}></StyledTypography>
        </div>
      ),
    },
    {
      question: "How can I submit my activities?",
      answer: (
        <StyledTypography style={{ padding: "1.5rem" }}></StyledTypography>
      ), // Placeholder for the actual contract address
    },
    {
      question: "How can I trade YOUTH Voucher?",
      answer: (
        <div>
          <StyledTypography style={{ padding: "1.5rem" }}></StyledTypography>
        </div>
      ),
    },
  ];

  return (
    <section id="faq" className="flex flex-column justify-center align-center">
      <p className="title">FAQ</p>
      <div className="flex flex-column align-center gap-1 width-100">
        {faqData.map((faq, index) => (
          <StyledAccordion key={index}>
            <StyledAccordionSummary classes={{ content: "customContentClass" }}>
              <StyledTypography style={{ fontWeight: "600" }}>
                {faq.question}
              </StyledTypography>
              <ArrowDropDownIcon />
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <hr />
              {faq.answer}
            </StyledAccordionDetails>
          </StyledAccordion>
        ))}
      </div>
    </section>
  );
};

export default FAQSection;
