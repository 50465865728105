import "./App.css";
import React from "react";
import Footer from "./Components/Footer/Footer";
import Body from "./Components/Body/Body";
import Top from "./Components/Top/Top";

function App() {
  return (
    <div className="App">
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter&display=swap"
        rel="stylesheet"
      ></link>
      <Body />
      <Footer />
      <Top />
    </div>
  );
}

export default App;
