import React, { useState, useEffect } from "react";
import "./styles.css";
const KaikasButton = () => {
  const [walletAddress, setWalletAddress] = useState(null);

  useEffect(() => {
    const fetchWalletAddress = async () => {
      try {
        const kaikasInstalled = await window.klaytn.enable();
        if (kaikasInstalled) {
          console.log("Kaikas wallet detected. Connecting...");
          const address = await window.klaytn.selectedAddress;
          setWalletAddress(address);
        }
      } catch (error) {
        console.error("Error connecting with Kaikas:", error);
      }
    };

    fetchWalletAddress();
  }, []); // 빈 배열을 전달하여 컴포넌트 마운트 시에만 실행되도록 함

  const handleKaikasConnect = async () => {
    try {
      const kaikasInstalled = await window.klaytn.enable();
      if (kaikasInstalled) {
        console.log("Kaikas wallet detected. Connecting...");
        const address = await window.klaytn.selectedAddress;
        setWalletAddress(address);
      } else {
        window.open(
          "https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi",
          "_blank"
        );
      }
    } catch (error) {
      console.error("Error connecting with Kaikas:", error);
      window.open(
        "https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi",
        "_blank"
      );
    }
  };

  const summarizeAddress = (address) => {
    if (address) {
      const summary = `${address.substring(0, 6)}...${address.substring(
        address.length - 4
      )}`;
      return summary;
    }
    return "";
  };

  return (
    <div>
      {walletAddress ? (
        <button className="connect-button">
          {summarizeAddress(walletAddress)}
        </button>
      ) : (
        <button onClick={handleKaikasConnect} className="connect-button">
          <img alt="" src="/images/symbol_white_transparent.svg" width={18} />
          <span>Connect to Kaikas</span>
        </button>
      )}
    </div>
  );
};

export default KaikasButton;
