// Header.js
import React, { useEffect, useState } from "react";
import "./Header.css";
import Navigation from "../Navigation/Navigation";

const Header = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="header">
      <div className="logo">
        <a href="/">
          {windowWidth > 768 && (
            <img src="/logo.svg" alt="Logo" width={200} height={100} />
          )}
        </a>
      </div>
      <Navigation />
    </header>
  );
};

export default Header;
