// Top.js
import React, { useState, useEffect } from "react";
import "./Top.css"; // You may want to create a separate CSS file for styling
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function Top() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    // Add a scroll event listener to detect when the user scrolls
    window.onscroll = () => {
      // Show the button when the user scrolls down, hide it when at the top
      setShowButton(window.scrollY > 100);
    };

    // Clean up the event listener when the component unmounts
    return () => {
      window.onscroll = null;
    };
  }, []);

  const scrollToTop = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Add smooth scrolling for a nicer effect
    });
  };

  return (
    // Render the button only if showButton is true
    showButton && (
      <div className="top-button">
        <button onClick={scrollToTop}>
          <KeyboardArrowUpIcon />
        </button>
      </div>
    )
  );
}

export default Top;
