// Footer.js
import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div id="wrap">
      <footer>
        <nav>
          <a
            href="https://twitter.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>{" "}
          |{" "}
          <a
            href="https://github.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Github
          </a>{" "}
          {/* <a
            href="https://telegram.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Telegram
          </a>{" "}
          |{" "} */}
          {/* <a
            href="https://discord.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord
          </a> */}
        </nav>
        <div>
          <span>Copyright 2023. Youth Project. All Rights Reserved.</span>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
